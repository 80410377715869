import Velocity from 'velocity-animate';

const BookNowLink = () => {
	const scrollToCkeckout = () => {
		Velocity( document.getElementById( "checkout" ), "scroll", { duration:800 } )
	}
	return(
	    <div className="products mobile-only" style={{ paddingBottom:'0', paddingTop:'20px', marginLeft:'-10px', userSelect:'none', cursor:'pointer' }} >
			<div 
				onClick={ scrollToCkeckout }
				className="green"
				style={{ display:'inline-block', margin:'0 0 10px 10px', padding:'6px 26px 14px 10px', fontSize:'16px', fontWeight:600, borderRadius:'5px', color:'white' }} 
			>
				<i 
					style={{ fontSize:'24px', transform:'translate(10px, 5px)', paddingRight:'14px' }} 
					className="material-icons"
				>
					arrow_downward
				</i>
				Book Now
			</div>
		</div>
	)
}

export default BookNowLink
