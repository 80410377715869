import React, { useState, useEffect } from 'react';
import Request from '../Request/Request';

const Register = props => {

  const [ done, setDone ] = useState( false )
  const [ name, setName ] = useState( '' )
  const [ email, setEmail ] = useState( '' )

  useEffect(() => {
  }, [] )

  const send = () => {
    if ( name.length < 4 || email.length < 5 ) return
    Request.post( 'postinglist', { name:name, email:email }, result => {
      setDone( true ) 
    } )
  }

  return ( 
    <div id="register" className="info" style={{ padding:'20px 30px 0px 30px', margin:'0', boxSizing:'border-box' }}  >
      <h1 style={{ padding:'0 0 20px 0', margin:0, boxSizing:'border-box', userSelect:'none' }} >Register</h1>
      { done &&
        <div style={{ padding:'0 0 20px 0', margin:0, boxSizing:'border-box' }} >
          <p style={{ margin:0, padding:'0 0 10px 0', boxSizing:'border-box' }} >
            Thank You!
          </p>
          <p style={{ margin:0, padding:'0 0 10px 0', boxSizing:'border-box' }} >
            You have been registered to be updated on latest news and products.
          </p>
        </div>
      }
      { !done &&
        <div style={{ padding:'0 0 20px 0', margin:0, boxSizing:'border-box' }} >
          <p style={{ margin:0, padding:'0 0 10px 0', boxSizing:'border-box' }} >
            Please register if you want to be updated on latest news and products.
          </p>
          <p style={{ margin:0, padding:'0 0 10px 0', boxSizing:'border-box' }} >
            <label htmlFor="name">Name</label>
            <input 
              id="name" 
              name="name" 
              type="text" 
              value={ name } 
              onChange={ e => setName( e.target.value ) } 
            />
          </p>
          <p style={{ margin:0, padding:'0 0 10px 0', boxSizing:'border-box' }} >
            <label htmlFor="email">Email</label>
            <input 
              id="email" 
              name="email" 
              type="email" 
              value={ email } 
              onChange={ e => setEmail( e.target.value ) } 
            />
          </p>
          <p style={{ margin:0, padding:'15px 0 0 0', boxSizing:'border-box' }}  >
            <span 
              onClick={ send } 
              className={ "button-front orange" } 
              style={{ cursor:'pointer', margin:'20px 0 0 0' }} 
            >
              Register
            </span>
          </p>
        </div>
      }
    </div>
  ) 


}

export default Register

