import Store from '../Store/Store';
import BlogsList from '../Blogs/BlogsList';
import { content } from '../Data/content'

const About = props => { 

	const domain = Store.get( 'domain' )

    if ( !domain ) return

	return (
	    <div 
	      id="about" 
	      className="info-wrapper" 
	      style={{ 
	        background:props.background, 
	        boxSizing:'border-box', 
	        padding:'0 20px 0 0' 
	      }} 
	    >
	      <div className="products"  >
	      	{/*
	        <h1 style={{ padding:'20px 30px 0px 30px', margin:'0', boxSizing:'border-box' }}>{ content[ domain ].about.title }</h1>
	        */}
	        { content[ domain ].about.columns.map( c => (
	          <div key={ c.title } className="info" style={{ padding:'10px 30px 10px 30px', margin:'0', boxSizing:'border-box' }}>
	            <h1 style={{ marginTop:'10px' }} >{ c.title }</h1>
	            { c.paragraphs.map( p => (
	              <p key={ p } >{ p }</p>
	            ) ) }
	          </div>
	        ) ) }
	      </div>
	    </div>
	)

}

export default About
