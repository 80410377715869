import React,{ Fragment } from 'react';
import GalleryView from '../Gallery/GalleryView';
//import Velocity from 'velocity-animate';

class Gallery extends React.Component {

	constructor( props ) {
		super( props );
		this.state = { 
			tmp:null,
			galleryVisible:false,
			imageIndex:0,
			scrollTop:0,
		}
	    this.hideGallery = this.hideGallery.bind(this);
	}

	showGallery( imageIndex ) {
		let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
		document.body.style.overflow = 'hidden'
		document.documentElement.style.position = 'fixed'
		this.setState( { galleryVisible:true, imageIndex:imageIndex, scrollTop:scrollTop } )
	}

	hideGallery() {
		document.body.style.overflow = 'scroll'
		document.documentElement.style.position = ''
		//Velocity( this.refs.gallery, "scroll", { duration:100 } )
		document.documentElement.scrollTop = this.state.scrollTop
    	document.body.scrollTop = this.state.scrollTop // Safari

		this.setState( { galleryVisible:false } )
	}

	render() {
		return ( 
			<Fragment>

		    	<h2 ref="gallery" style={{ marginTop:'0', paddingTop:'20px' }} >Image Gallery</h2>

		    	<div style={{ userSelect:'none' }} >
			    	{ this.props.thumbnails.map( ( p, i ) => (
						<img 
							key={ p } 
							alt=""
							draggable="false" 
							onClick={ e => this.showGallery( i ) }
							style={{ padding:'3px', margin:'0 3px', width:"86px", height:"86px", background:'white', cursor:'pointer', boxShadow:'0 1px 3px rgba(0,0,0,0.2)' }} 
							src={ p } 
							onError={ e => { e.target.src = process.env.PUBLIC_URL + '/img/blue.png' } } 
						/> 
					) ) }
		    	</div>

		    	<div style={{ fontSize:'12px', textAlign:'left' }} >Click any thumbnail to display the image gallery full-screen</div>

		    	{ this.state.galleryVisible && 
		    		<GalleryView
		    			images={ this.props.images }
		    			imageIndex={ this.state.imageIndex }
		    			hideGallery={ this.hideGallery }
		    		/> 
		    	}

			</Fragment>
		)
	}
}

export default Gallery
