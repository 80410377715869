import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Request from '../Request/Request';
import Store from '../Store/Store';
import Synchronize from '../Synchronize/Synchronize';
import ActivityCard from '../Activities/ActivityCard';
import Search from '../Activities/Search';

const ActivitiesList = props => {

	const [ tmp, setTmp ] = useState( 0 )
	const [ search, setSearch ] = useState( '' )

	useEffect(() => {
		Request.get( 'activities/' + props.productListId, 
			result => { 
				Store.set( 'activities.' + props.productListId, result.data.items ) 
				setTmp( tmp + 1 )
			}
		) 
	}, [ props.productListId ] )

	let data = Store.get( 'activities.' + props.productListId ) || []
	if ( search !== '' ) data = data.filter( a => { 
		let searchString = a.activity.title + ' ' + a.activity.excerpt
		if ( a.activity.locationCode && a.activity.locationCode.name ) searchString += ' ' + a.activity.locationCode.name
		return searchString.toLowerCase().includes( search.trim().toLowerCase() ) 
	} )
	let limit = props.limit || 12

	return ( 
    	<div className="products" >
	    	<div style={{ verticalAlign:'top', textAlign:'left', marginLeft:'10px', marginRight:'30px', boxSizing:'border-box', }} >

	    		<Search search={ search } setSearch={ setSearch } />

	    		{ data && data != null && data.length > 0 && data.map( ( row, i ) => {
	    			if ( i >= Number( limit ) ) return <Fragment key={ props.productListId + '-' + row.activity.id  + '-' + i } />
    				return (
    					<Fragment key={ props.productListId + '-' + row.activity.id  + '-' + i } >
				    		<Link 
	 				    		draggable="false" 
					    		to={ '/activities/' + props.productListId + '/' + row.activity.id + '/' }
					    		className="activities-list-block"
				    		>
								<ActivityCard
									row={ row } 
									productListId={ props.productListId }
								/>
					    	</Link>
    					</Fragment>
			    	)
		    	} ) }
		    </div>
		</div>
	)
	
}

export default ActivitiesList

