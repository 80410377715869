const OpenInNewIcon = props => (
  <span className="material-icons" style={{ color:'#ffffffbb', fontSize:'16px', margin:'0 5px 0 0', transform:'translateY(4px)' }} >
    open_in_new
  </span>          
)

const Open = props => (
  <>
    <a 
      href={ props.href }
      target="_blank"
      rel="noopener noreferrer"
    >
      <OpenInNewIcon />
    </a>
    <a 
      className="front-link-hover"
      style={{ color:'white', fontSize:'12px', lineHeight:'14px', margin:'0 0 4px 0' }} 
      href={ props.href }
      target="_blank"
      rel="noopener noreferrer"
    >
      { props.title }
    </a>
    <br/>
  </>
)

export default Open
