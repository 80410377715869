import React, { useState, useEffect } from 'react';
import Data from '../Data/Data';
import FrontPage from '../FrontPage/FrontPage';
import Activities from '../Activities/Activities';
import Synchronize from '../Synchronize/Synchronize';
import Blogs from '../App/Blogs';
import Instructions from '../App/Instructions';
import Register from '../App/Register';
import About from '../App/About';
import ToTop from '../App/ToTop';

const Intro = props => {

  const [ tmp, setTmp ] = useState( 0 )

  useEffect(() => {
    window.scrollTo( { top: 0, left: 0 } );
    Data().fetch( ( data ) => {
      Synchronize()
      setTmp( tmp + 1 )
    } )
  }, [  ] )

  return ( 
    <>
      {/*
      */}

      <FrontPage /> 

      <Activities />

      <Blogs background="#fff" />


      <div 
        className="info-wrapper" 
        style={{ 
          boxSizing:'border-box', 
          padding:'0px 20px 0 0',          
          background:'#fff'
        }} 
      >
        <div className="products" >
          <Instructions />
          <Register />
        </div>
      </div>


      <About background="#fff" />

      <ToTop />

    </>
  )

}

export default Intro
