const ShowMap = props => (
	<iframe 
	  width="100%" 
	  title="map"
	  height="300" 
	  style={{ border:'5px solid white', borderRadius:'5px', boxShadow:'0 1px 6px rgba(0,0,0,0.2)', marginTop:'20px', marginBottom:'30px' }}
	  frameBorder="0" 
	  scrolling="no" 
	  src={ 'https://maps.google.com/maps?q='+props.lat+','+props.lng+'&hl=es;z=' + ( props.zoom || 14 ) + '&output=embed' }
	>
	</iframe>
)

export default ShowMap
