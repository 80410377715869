import Request from '../Request/Request';
import Store from '../Store/Store';
import Data from '../Data/Data';

const Synchronize = ( productListId, callBack, notFound ) => {

    //let d = Store.get( 'activities.fetchTime' ) || new Date( 0 ).getTime()
    //if ( new Date().getTime() - d > ( 5 * 60 * 1000 ) ) {
      let productLists = Data().productLists
      if ( Request && productLists !== null && productLists !== undefined && productLists.length > 0 ) {
        productLists.forEach( a => { 
          Request.get( 'activities/' + a.productListId, 
            result => { 
              Store.set( 'activities.' + a.productListId, result.data.items ) 
              result.data.items.forEach( ( r, i ) => {
                //if ( i >= 12 ) return  
                //new Image().src = r.activity.keyPhoto.derived[ 1 ].url // cache main images
              } ) 
              Store.set( 'activities.fetchTime', new Date().getTime() )
              if ( typeof productListId !== 'undefined' && typeof callBack !== 'undefined' && productListId === a.productListId ) {
              	callBack()
              }
            }
          ) 
        } )
      }
    //}

}

export default Synchronize
