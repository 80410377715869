import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App/App';
import Store from './Store/Store';


const container = document.getElementById('root');
const root = createRoot(container);

//Store.init( () => {


Store.init( () => {
  Store.clear( () => {

    let domain = 'ae'
    switch ( window.document.domain ) {
      case 'stopover.fi': domain = 'fi'; break;
      case 'stopover.qa': domain = 'qa'; break;
      case 'stopover.is': domain = 'is'; break;
      case 'stopover.ae': domain = 'ae'; break;
    }
    Store.set( 'domain', domain )
    const render = () => {
      root.render(
        <App />
      )
    }
    render()

  } )
} ) 


//})


/*

const render = () => {
    root.render(
        <App />
    )
}
render()

-- ae

63121 Adventure
63122 Culture
63123 Convenience
63124 Recommended
63125 Cruise selection

*/

/*

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App'

import { hotjar } from 'react-hotjar';
hotjar.initialize( 1100659, 6 );

ReactDOM.render((
    <App/>
), document.getElementById( "root" ) )

*/
