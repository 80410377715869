import Store from '../Store/Store';
import BlogsList from '../Blogs/BlogsList';
import { content } from '../Data/content'

const Instructions = props => { 

  	const domain = Store.get( 'domain' )

    if ( !domain ) return

	/*
	  <div style={{ boxSizing:'border-box', padding:'20px 20px 10px 0' }} >
	  </div>
	*/

	return (
	    <div id="instructions" className="info" style={{ padding:'20px 30px 0px 30px', margin:0, boxSizing:'border-box' }} >
	      <h1 style={{ padding:'0 0 20px 0', margin:'0', boxSizing:'border-box' }}>
	        { content[ domain ].instructions.title }
	      </h1>
	      { content[ domain ].instructions.paragraphs.map( p => (
	        <p key={ p } style={{ margin:0, padding:'0 0 10px 0', boxSizing:'border-box', textAlign:'left' }} >
	          { p } 
	        </p>
	      ) ) }
	    </div>
	) 
}

export default Instructions
