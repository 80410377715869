import React, { useState, Fragment, useEffect } from 'react';
import Store from '../Store/Store';
import Data from '../Data/Data';
import BreadCrumbs from '../App/BreadCrumbs';
import ActivitiesList from '../Activities/ActivitiesList';
//import Tabs from '../Activities/Tabs';
import Pills from '../Activities/Pills';

const Activities = props => {

	const [ productLists, setProductLists ] = useState( Data().productLists || [] )
	const [ tmp, setTmp ] = useState( 0 )

	useEffect(() => {

		//document.body.scrollTop = Store.get( 'allActivities.scrollTop' ) || 0
		//document.documentElement.scrollTop = Store.get( 'allActivities.scrollTop' ) || 0
		window.scrollTo( { top: 0, left: 0 } );

		Data().fetch( ( data ) => {
			setProductLists( data.productLists )
		} )

	}, [] )

	const selectTab = i  => {
		Store.set( 'allActivities.productListIndex', i ) 
		setTmp( tmp + 1 )
	}

	let productListIndex = Store.get( 'allActivities.productListIndex' ) || 0 
	let backTo = '/'

	if ( productLists === null || productLists === undefined || productLists.length === 0 ) {
		return null
	}

	return ( 
		<Fragment>

	    	<Pills
	    		productLists={ productLists }
	    		productListIndex={ productListIndex }
	    		selectTab={ selectTab }
	    	/>

	        <div className="products" style={{ paddingBottom:'10px' }} >
    			<div style={{ padding:'0 30px 0 20px' }} >
			    	<h1 style={{ margin:0, padding:0, textAlign:'left' }} >
			    		{ productLists[ productListIndex ].title }
		    		</h1>
		    	</div>
		    	{ productLists[ productListIndex ].lead &&
    			<div style={{ padding:'0 30px 0 20px' }} >
			    	<div style={{ fontWeight:400, lineHeight:'20px', margin:'20px 0 20px 0', textAlign:'left' }} >
			    		{ productLists[ productListIndex ].lead }
			    	</div>
		    	</div>
		    	}
    			<div style={{ padding:'0 30px 0 20px' }} >
			    	<h3 style={{ lineHeight:'20px', fontWeight:600, margin:'20px 0 10px 0', textAlign:'left' }} >
			    		Book { productLists[ productListIndex ].title } Activities
			    	</h3>
		    	</div>
	    	</div>

			<ActivitiesList
				limit="96"
				productListId={ productLists[ productListIndex ].productListId }
			/>

		</Fragment>
	)

}

export default Activities

