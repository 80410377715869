import React, { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; // must import Router, else switch doesn't work
import '../css/index.css';
import '../css/front.css';
import '../css/activities.css';
import '../css/card.css';
import '../css/products.css';
import '../css/banner.css';
import '../css/gallery.css';
import '../css/footer.css';
import Intro from '../App/Intro';
import Activities from '../Activities/Activities';
import ActivityPage from '../Activities/ActivityPage';
import CategoryPage from '../Categories/CategoryPage';
import BlogPage from '../Blogs/BlogPage';
import NewsPage from '../News/NewsPage';
import Footer from '../Footer/Footer';

const App = ( props ) => {
  return (
    <Fragment>
      <Router basename={ process.env.PUBLIC_URL }>
        <Routes>
          <Route path="/" element={ <Intro /> }/>
          <Route path="/activities/" element={ <Activities /> }/>
          <Route path="/activities/:productListId/:id/" element={ <ActivityPage /> }/>
          <Route path="/categories/:productListId/" element={ <CategoryPage /> }/>
          <Route path="/blogs/:slug/" element={ <BlogPage /> }/>
          <Route path="/news/:slug/" element={ <NewsPage /> }/>
          <Route path="*" element={ <Navigate replace to="/" /> } />
        </Routes>
      </Router>
      <Footer/>
    </Fragment>
  ) 
}

export default App 
