import React,{ Fragment } from 'react';
import parse from 'html-react-parser';
import Data from '../Data/Data';
import BreadCrumbs from '../App/BreadCrumbs';
//import ActivitiesList from '../Activities/ActivitiesList';

class NewsPage extends React.Component {

	constructor( props ) {
		super( props );
		this.state = { 
			tmp:null,
			newsListIndex:0,
		};
	}

	componentDidMount() {
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
		Data.news.forEach( ( a, i ) => {
			if ( a.slug === this.props.match.params.slug ) {
				this.setState( { newsListIndex:i } )
			}
		} )
	}

	render() {

		let backTo = '/'
		if ( this.props.location.state && this.props.location.state.backFromPageTo && this.props.location.state.backFromPageTo === 'back' ) {
			backTo = 'back'
		}

		return ( 
			<Fragment>

	    		<BreadCrumbs 
	    			to={ backTo }
	    			history={ this.props.history }
	    			img={ process.env.PUBLIC_URL + '/img/fi/' + Data.news[ this.state.newsListIndex ].img } 
	    			titles={ [ 'News', Data.news[ this.state.newsListIndex ].title ] } 
    			/>

		        <div className="products" >
	    			<div style={{ padding:'0 30px 10px 30px' }} >
				    	<h1 style={{ lineHeight:'50px', margin:'20px 0 0 0', textAlign:'left' }} >{ Data.news[ this.state.newsListIndex ].title }</h1>
			    	</div>
		    	</div>

		        <div className="products" >
	    			<div style={{ textAlign:'left', padding:'0 30px 10px 30px' }} >
		 		    	{ Data.news[ this.state.newsListIndex ].date || '' }
			    	</div>
		    	</div>

		        <div className="products" >
	    			<div style={{ textAlign:'left', padding:'0 30px 10px 30px' }} >
		 		    	{ parse( Data.news[ this.state.newsListIndex ].copy || '' ) }
			    	</div>
		    	</div>

			</Fragment>
		)
	}
}

export default NewsPage

