import React,{ Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import bgImage from '../img/stopover-logo-all-white.svg';
const bgUrl = `${ bgImage }`

const BreadCrumbs = props => { 
	const navigate = useNavigate()
	return (
		<div 
			onClick={ () => { props.to === 'back' ? navigate(-1) : navigate( props.to, { replace: true } ) } } 
			style={{ position:'relative', cursor:'pointer' }} 
			className="banner-parent" 
		>
			<div className="banner-child" >
				<img 
					className="banner-img" 
					alt={ props.img } 
					src={ props.img } 
					onError={ e => { e.target.src = process.env.PUBLIC_URL + '/img/blue.png' } } 
				/>
			</div>
		    <div className="front-shadow" />
			<div style={{ position:'absolute', width:'100%', maxWidth:'1240px', height:'100%', top:0, left:'50%', transform:'translateX(-50%)', boxSizing:'border-box' }} >
	            <img 
	            	className="shadow" 
	            	style={{ position:'absolute', left:0, top:0, width:'150px', padding:'10px 0 0 20px', textShadow:'0 2px 4px rgba(0,0,0,0.5)'}} 
	            	draggable="false" 
	            	alt="Logo" 
	            	src={ bgUrl }
            	/>
    			<div style={{ 
    				position:'absolute',
    				left:0, bottom:0, 
    				wordWrap:'normal', 
    				width:'100%', textAlign:'left', 
    				padding:'10px 30px 20px 20px', 
    				boxSizing:'border-box',
    				opacity:'0.75', color:'white', 
    				fontSize:'13px', lineHeight:'24px', 
    				fontWeight:600, 
    				}} 
				>
					Home
    				{ props.titles.map( ( a, i ) => (
    					<Fragment key={ i }>
							{ ' > ' + a }
    					</Fragment>
					) ) }
    			</div>
			</div>
		</div>
	)
}

export default BreadCrumbs