import React from 'react';
import Velocity from 'velocity-animate';
import parse from 'html-react-parser';
import Store from '../Store/Store';
import Open from '../Footer/Open';
import Sites from '../Footer/Sites';
import bgImage from '../img/stopover-logo-all-white.svg';
import { content } from '../Data/content'
const bgUrl = `${ bgImage }`

const scrollToTop = () => {
    Velocity( document.documentElement, "scroll", { duration:1000, easing:"easeOutQuad" } )
}

const Footer = () => { 

  const domain = Store.get( 'domain' )
  const c = content[ domain ]
  const f = c.footer

  if ( !domain || !c || !f ) return null

  return (

    <div className="footer-wrapper" >
      <div style={{ textAlign:'center', padding:'20px' }} >
        <img 
          onClick={ () => { scrollToTop() } } 
          style={{ width:'120px', cursor:'pointer' }} 
          alt="Logo" 
          src={ bgUrl }
        />
      </div>
      <div className="footer" >
        <div className="footer-flex-box" >
          { f.map( ( a, i ) => (
            <div key={ i } className="footer-item-group" >
              <strong style={{ color:'#fff', fontWeight:800, fontSize:'13px' }} >
                { a.title.toUpperCase() }
              </strong><br/>
              { a.links && a.links.map( ( b, j ) => (
                <Open key={ j } title={ b.title } href={ b.href } />
              ) ) }
              { a.copy && parse( a.copy ) }
            </div>
          ) ) }
        </div>
      </div>

      <div style={{ textAlign:'center', padding:'0 20px 40px 20px' }} >
        { parse( c.copyright || '' ) }
      </div>

    </div>

  )
}

export default Footer 
