import React from 'react';

const BlogCard = props => (
	<table className="card-hover" >
		<tbody>
			<tr><td colSpan="2" style={{ height:'230px', borderRadius:'5px 5px 0 0' }} >
				<img 
					src={ 'https://stopover.fi/assets/' + props.row.img } 
					draggable="false" 
					alt="" 
					style={{ objectFit:'cover', width:'100%', height:'100%', borderRadius:'5px 5px 0 0', boxSizing:'border-box' }} 
				/>
			</td></tr>
			<tr><td colSpan="2" style={{ padding:'4px 10px 0 10px', verticalAlign:'top', boxSizing:'border-box' }} >
		    	<h4 style={{ padding:'0', margin:'0', opacity:0.5, fontSize:'12px', lineHeight:'18px', height:'18px', overflow:'hidden', boxSizing:'border-box' }} >
		    		{ props.row.display_date }
	    		</h4>
			</td></tr>
			<tr><td colSpan="2" style={{ padding:'4px 10px 5px 10px', verticalAlign:'top', borderRadius:'0 0 5px 5px', boxSizing:'border-box' }} >
		    	<h3 className="card-title" style={{ padding:'0', margin:'0', paddingBottom:'10px', fontSize:'16px', lineHeight:'20px', overflow:'hidden', boxSizing:'border-box' }} >
		    		{ props.row.title }
		    	</h3>
			</td></tr>
		</tbody>
	</table>
)

export default BlogCard
