
export const content = {

  common:{
    sites:[
      'Stopover.fi',
      'Stopover.is',
      'Stopover.qa',
      'Stopover.ae',
    ]
  },

  fi: {

    site:'Stopover.fi',
    api:'https://stopover.fi/api/',

    bookUrl:'https://widgets.bokun.io/widgets/58055?bookingChannelUUID=01d86bf1-36f9-461b-8253-de724cafa93d',
    bookHash:'w58055_e3dd74d3_289e_4fa4_b266_58021497ec36',
    lang:'en',
    currency:'EUR',
    currencySymbol:'€',

    frontpage:{
      title:'Welcome to Finland!',
      lead:'Experience the unique beauty and culture of Finland all year around.',
      copy:'StopOver.fi is your reliable one-stop shop for meaningful moments during your stay.',
    },

    footer:[
      { 
        title:'STOPOVER.FI', 
        copy:`
          &#8453; Airtouch Ltd<br>
          Eerikinkatu 27<br>
          00180 Helsinki<br>
          FINLAND
        `, 
      },
      { 
        title:'AIRTOUCH LTD', 
        links:[
          { title:'airtouch.fi', href:'https://airtouch.fi' }
        ], 
        copy:`
          Tel. +358 20 155 7429<br>
          Email: stopover@airtouch.fi<br>
          Corporate Register Nr 1912298-0
        `, 
      },
      { 
        title:'TERMS OF USE', 
        links:[
          { title:'Terms and Conditions', href:process.env.PUBLIC_URL + "/docs/Airtouch_General_terms_and_conditions.pdf" },
          { title:'Privacy Policy', href:process.env.PUBLIC_URL + "/docs/Stopover_Privacy_Policy.pdf" },
        ] 
      },
      { 
        title:'INTERNATIONAL', 
        links:[
          { title:'Stopover.qa', href:'https://stopover.qa' },
          { title:'Stopover.ae', href:'https://stopover.ae' },
          { title:'Stopover.is', href:'https://stopover.is' },
        ] 
      },
    ],

    copyright:'&copy; Airtouch Ltd 2023 &nbsp; &#8212; &nbsp; Images &copy; Visit Finland 2023',

    blogs:{
      title:'Blogs',
      enabled:true,
    },

    instructions: {
      title:'Stopover.fi',
      paragraphs: [
        'Thank you for choosing us. Here you can view, book and pay tours safely. ',
        'We will email you a confirmation with a ticket, more information and a receipt of your payment. ',
        'We work with the best service providers in Finland. ',
      ]
    },

    about:{
      title: 'About Us',
      columns: [ {
          title: 'About Us',
          paragraphs: [
            'StopOver is your one-stop shop for meaningful moments during your visit. We are a leading service provider and work with the best in the industry.',
            'We have been in business since 2007s and we are proud of this quality service. Our main goal is to make your visit memorable.',
            'Choosing us you can book and pay quality services online and safely. If you want to give us feedback as a consumer or as a partner we are honored.',
            'We are committed to help our clients to find interesting and memorable experiences so that you can get more out of your stay.',
          ]
        }, {
          title: 'Meaningful Moments',
          paragraphs: [
            'We all want to enjoy authentic quality experiences. We have selected the best services, themes and seasons available in Finland. We are competitive and quality driven and hope you share our values',
            'Our main focus is on tours and activities, but we also connect and recommend restaurants, retail, accommodation and transportation.',
            'StopOver was launched 2017 in Finland. Our team has solid experience on aviation, travel and service industry. We are a dynamic and loyal partner for you as a client or business partner.',
            'Please enjoy your meaningful moments. Thank you for choosing us!',
          ]
        },
      ],
    }

  }, // fi

  is: {

    site:'Stopover.is',
    api:'https://stopover.is/api/',

    bookUrl:'https://widgets.bokun.io/widgets/58055?bookingChannelUUID=01d86bf1-36f9-461b-8253-de724cafa93d',
    bookHash:'w58055_e3dd74d3_289e_4fa4_b266_58021497ec36',
    lang:'en',
    currency:'EUR',
    currencySymbol:'€',

    frontpage:{
      title:'Welcome to Iceland!',
      lead:'Experience the unique beauty and culture of Iceland all year around.',
      copy:'StopOver.is is your reliable one-stop shop for meaningful moments during your stay.',
    },

    footer:[
      { 
        title:'STOPOVER.IS', 
        copy:`
          &#8453; Airtouch Ltd<br>
          Eerikinkatu 27<br>
          00180 Helsinki<br>
          FINLAND
        `, 
      },
      { 
        title:'AIRTOUCH LTD', 
        links:[
          { title:'airtouch.fi', href:'https://airtouch.fi' }
        ], 
        copy:`
          Tel. +358 20 155 7429<br>
          Email: stopover@airtouch.fi<br>
          Corporate Register Nr 1912298-0
        `, 
      },
      { 
        title:'TERMS OF USE', 
        links:[
          { title:'Terms and Conditions', href:process.env.PUBLIC_URL + "/docs/Airtouch_General_terms_and_conditions.pdf" },
          { title:'Privacy Policy', href:process.env.PUBLIC_URL + "/docs/Stopover_Privacy_Policy.pdf" },
        ] 
      },
      { 
        title:'INTERNATIONAL', 
        links:[
          { title:'Stopover.fi', href:'https://stopover.fi' },
          { title:'Stopover.qa', href:'https://stopover.qa' },
          { title:'Stopover.ae', href:'https://stopover.ae' },
        ] 
      },
    ],

    copyright:'&copy; Airtouch Ltd 2023',

    blogs:{
      title:'Blogs',
      enabled:true,
    },

    instructions: {
      title:'Stopover.is',
      paragraphs: [
        'Thank you for choosing us. Here you can view, book and pay tours safely. ',
        'We will email you a confirmation with a ticket, more information and a receipt of your payment. ',
        'We work with the best service providers in Iceland. ',
      ]
    },

    about:{
      title: 'About Us',
      columns: [ {
          title: 'About Us',
          paragraphs: [
            'StopOver is your one-stop shop for meaningful moments during your visit. We are a leading service provider and work with the best in the industry.',
            'We have been in business since 2007 and we are proud of this quality service. Our main goal is to make your visit memorable.',
            'Choosing us you can book and pay quality services online and safely. If you want to give us feedback as a consumer or as a partner we are honored.',
            'We are committed to help our clients to find interesting and memorable experiences so that you can get more out of your stay.',
          ]
        }, {
          title: 'Meaningful Moments',
          paragraphs: [
            'We all want to enjoy authentic quality experiences. We have selected the best services, themes and seasons available in Iceland. We are competitive and quality driven and hope you share our values',
            'Our main focus is on tours and activities, but we also connect and recommend restaurants, retail, accommodation and transportation.',
            'StopOver was launched in 2017. Our team has solid experience on aviation, travel and service industry. We are a dynamic and loyal partner for you as a client or business partner.',
            'Please enjoy your meaningful moments. Thank you for choosing us!',
          ]
        },
      ],
    }

  }, // fi

  qa: {

    site:'Stopover.qa',
    api:'https://stopover.qa/api/',

    bookUrl:'https://widgets.bokun.io/widgets/53214?bookingChannelUUID=1b710447-b6bb-468b-8502-8dcfab9f5c17',
    bookHash:'w53214_6ab1712f_bb7b_4fd5_8364_1f329c6c3af9',
    lang:'en',
    currency:'USD',
    currencySymbol:'$',

    frontpage:{
      title:'Welcome to Qatar!',
      lead:'Experience the unique beauty and culture of Qatar all year around.',
      copy:'StopOver.qa is your reliable one-stop shop for meaningful moments during your stay.',
    },

    footer:[
      { 
        title:'STOPOVER.QA', 
        copy:`
          StopOver Tourism LLC<br>
          P.O.Box 37772, Building 7,<br>
          Al Noor Street, Doha, QATAR<br>
          Corporate Reg. Nr. 131019<br>
        `, 
      },
      { 
        title:'TERMS OF USE', 
        links:[
          { title:'Terms and Conditions', href:process.env.PUBLIC_URL + "/docs/General-terms-and-conditions-190501.pdf" },
          //{ title:'Privacy Policy', href:process.env.PUBLIC_URL + "/docs/Stopover_Privacy_Policy.pdf" },
        ] 
      },
      { 
        title:'INTERNATIONAL', 
        links:[
          { title:'Stopover.ae', href:'https://stopover.ae' },
          { title:'Stopover.fi', href:'https://stopover.fi' },
          { title:'Stopover.is', href:'https://stopover.is' },
        ] 
      },
      { 
        title:'', 
        copy:`
        `, 
      },
    ],

    copyright:'&copy; StopOver Tourism LLC 2023',

    blogs:{
      title:'Blogs',
      enabled:false,
    },

    instructions: {
      title:'Stopover.qa',
      paragraphs: [
        'Thank you for choosing us. Here you can view, book and pay tours safely. ',
        'We will email you a confirmation with a ticket, more information and a receipt of your payment. ',
        'We work with the best service providers in Qatar. ',
      ]
    },

    about:{
      title: 'About Us',
      columns: [ {
          title: 'About Us',
          paragraphs: [
            'StopOver is your one-stop shop for meaningful moments during your visit. We are a leading service provider and work with the best in the industry.',
            'We have been in business since 2007 and we are proud of this quality service. Our main goal is to make your visit memorable.',
            'Choosing us you can book and pay quality services online and safely. If you want to give us feedback as a consumer or as a partner we are honored.',
            'We are committed to help our clients to find interesting and memorable experiences so that you can get more out of your stay.',
          ]
        }, {
          title: 'Meaningful Moments',
          paragraphs: [
            'We all want to enjoy authentic quality experiences. We have selected the best services, themes and seasons available in Qatar. We are competitive and quality driven and hope you share our values',
            'Our main focus is on tours and activities, but we also connect and recommend restaurants, retail, accommodation and transportation.',
            'StopOver was launched in 2017. Our team has solid experience on aviation, travel and service industry. We are a dynamic and loyal partner for you as a client or business partner.',
            'Please enjoy your meaningful moments. Thank you for choosing us!',
          ]
        },
      ],
    }

  }, // fi

  ae: {

    site:'Stopover.ae',
    api:'https://stopover.qa/api/',

    bookUrl:'https://widgets.bokun.io/widgets/53214?bookingChannelUUID=1b710447-b6bb-468b-8502-8dcfab9f5c17',
    bookHash:'w53214_6ab1712f_bb7b_4fd5_8364_1f329c6c3af9',
    lang:'en',
    currency:'USD',
    currencySymbol:'$',

    frontpage:{
      title:'Welcome to Dubai!',
      lead:'Experience the unique beauty and culture of Dubai all year around.',
      copy:'StopOver.ae is your reliable one-stop shop for meaningful moments during your stay.',
    },

    footer:[
      { 
        title:'STOPOVER.QA', 
        copy:`
          StopOver Tourism LLC<br>
          P.O.Box 37772, Building 7,<br>
          Al Noor Street, Doha, QATAR<br>
          Corporate Reg. Nr. 131019<br>
        `, 
      },
      { 
        title:'TERMS OF USE', 
        links:[
          { title:'Terms and Conditions', href:process.env.PUBLIC_URL + "/docs/General-terms-and-conditions-190501.pdf" },
          //{ title:'Privacy Policy', href:process.env.PUBLIC_URL + "/docs/Stopover_Privacy_Policy.pdf" },
        ] 
      },
      { 
        title:'INTERNATIONAL', 
        links:[
          { title:'Stopover.qa', href:'https://stopover.qa' },
          { title:'Stopover.fi', href:'https://stopover.fi' },
          { title:'Stopover.is', href:'https://stopover.is' },
        ] 
      },
      { 
        title:'', 
        copy:`
        `, 
      },
    ],

    copyright:'&copy; StopOver Tourism LLC 2023',

    blogs:{
      title:'Blogs',
      enabled:false,
    },

    instructions: {
      title:'Stopover.ae',
      paragraphs: [
        'Thank you for choosing us. Here you can view, book and pay tours safely. ',
        'We will email you a confirmation with a ticket, more information and a receipt of your payment. ',
        'We work with the best service providers in Dubai. ',
      ]
    },

    about:{
      title: 'About Us',
      columns: [ {
          title: 'About Us',
          paragraphs: [
            'StopOver is your one-stop shop for meaningful moments during your visit. We are a leading service provider and work with the best in the industry.',
            'We have been in business since 2007 and we are proud of this quality service. Our main goal is to make your visit memorable.',
            'Choosing us you can book and pay quality services online and safely. If you want to give us feedback as a consumer or as a partner we are honored.',
            'We are committed to help our clients to find interesting and memorable experiences so that you can get more out of your stay.',
          ]
        }, {
          title: 'Meaningful Moments',
          paragraphs: [
            'We all want to enjoy authentic quality experiences. We have selected the best services, themes and seasons available in Dubai. We are competitive and quality driven and hope you share our values',
            'Our main focus is on tours and activities, but we also connect and recommend restaurants, retail, accommodation and transportation.',
            'StopOver was launched in 2017. Our team has solid experience on aviation, travel and service industry. We are a dynamic and loyal partner for you as a client or business partner.',
            'Please enjoy your meaningful moments. Thank you for choosing us!',
          ]
        },
      ],
    }

  }, 


}
