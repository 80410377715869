import Store from '../Store/Store';
import { content } from '../Data/content'

const ActivityCard = props => {

    const domain = Store.get( 'domain' )
    if ( !domain ) return
	const c = content[ domain ]

	let city = ''
	if ( props.row.activity.hasOwnProperty( 'locationCode' ) && props.row.activity.locationCode !== null  ) {
		if ( props.row.activity.locationCode.hasOwnProperty( 'name' ) && props.row.activity.locationCode.name !== null ) {
			city = props.row.activity.locationCode.name.toUpperCase()
		}
	}

	//console.log( 'props', props.row.activity.keyPhoto )

	const imageUrl = props.row.activity.keyPhoto ? props.row.activity.keyPhoto.derived[1].url : ''

	return (
		<table className="card-hover" >
			<tbody>
				<tr><td colSpan="2" style={{ height:'230px' }} >
					<img 
						id={ props.productListId + '-' + props.row.activity.id + '-' + imageUrl } 
						key={ props.productListId + '-' + props.row.activity.id + '-' + imageUrl } 
						src={ imageUrl }
						onError={ e => { e.target.src = process.env.PUBLIC_URL + '/img/blue.png' } } 
						draggable="false" 
						alt="" 
						style={{ objectFit:'cover', width:'100%', height:'100%', boxSizing:'border-box', borderRadius:'5px 5px 0 0' }} 
					/>
				</td></tr>

				<tr><td className="card-title" colSpan="2" style={{ padding:'5px 5px', verticalAlign:'top' }} >
			    	<h3 style={{ padding:'5px', margin:'0', fontSize:'16px', lineHeight:'20px', fontWeight:500, textAlign:'left', overflow:'hidden', boxSizing:'border-box' }} >
			    		{ props.row.activity.title }
		    		</h3>
				    {/*
			    	<p style={{ padding:'5px 10px', margin:'0', fontSize:'12px', lineHeight:'20px', height:'40px', overflow:'hidden', textOverflow:'ellipsis', verticalAlign:'top' }} >{ props.row.activity.excerpt }...</p>
			    	*/}
				</td></tr>
				<tr>
	    			<td style={{ margin:'0', width:'88%', boxSizing:'border-box', fontSize:'12px', padding:'0 5px 10px 5px', verticalAlign:'top' }} >
				    	<div style={{ padding:'5px 0 5px 5px', eight:'60px', lineHeight:'14px', textAlign:'left', verticalAlign:'top', boxSizing:'border-box'  }} >
				    		<span style={{ fontWeight:800 }} >{ city }</span><br/>
				    		{ props.row.activity.difficultyLevel && 
					    		<div style={{ display:'inline-block', background:'#0277bd', color:'#fff', fontSize:'10px', borderRadius:'9px', fontWeight:900, margin:'3px 0', padding:'2px 8px' }} >
					    			{ props.row.activity.difficultyLevel.replace( '_', ' ' ) }
					    		</div>
				    		}
				    		<div>
					    		{ props.row.activity.durationText.toLowerCase() }
				    		</div>
				    	</div>
	    			</td>
	    			<td style={{ margin:'0', width:'120px', boxSizing:'border-box', fontSize:'12px', padding:'0 10px 10px 0', verticalAlign:'bottom', textAlign:'right' }} >
				    	<div className="orange" style={{ width:'90px', height:'60px', borderRadius:'5px', padding:'4px 0 0 0', margin:'0 0 0 0', color:'white', lineHeight:'20px', textAlign:'center', verticalAlign:'top', boxSizing:'border-box'  }} >
					    	<span style={{ padding:'0 0 2px 0' }} >
					    		From
				    		</span>
				    		<br/>
					    	<span style={{ fontSize:'18px', fontWeight:600 }} >
					    		{ ( c.currency === 'USD' ? c.currencySymbol + ' ' : '' ) + Math.round( props.row.activity.nextDefaultPrice ) + ( c.currency === 'EUR' ? ' ' + c.currencySymbol : '' ) }
				    		</span>
				    	</div>
	    			</td>
				</tr>
			</tbody>
		</table>
	)
}

export default ActivityCard
