import Store from '../Store/Store';
import BlogsList from '../Blogs/BlogsList';
import { content } from '../Data/content'

const Blogs = props => { 

    const domain = Store.get( 'domain' )
    if ( !domain ) return

    if ( !content[ domain ].blogs.enabled ) return null

    return (
      <div id="blog" className="products-wrapper" style={{ background:props.background, padding:'20px 0 0 0' }} >
        <div className="products" style={{ paddingBottom:'15px' }} >
          <h1 
            style={{ 
              padding:'0px 30px 0 20px', 
              margin:'0', 
              boxSizing:'border-box', 
              userSelect:'none' 
            }} 
          >
            { content[ domain ].blogs.title }
          </h1>
        </div>
        <BlogsList
          limit="12"
          backFromPageTo="back" 
        />
      </div>
  )
}

export default Blogs

