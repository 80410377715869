import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Store from '../Store/Store';
import Data from '../Data/Data';
import BlogCard from '../Blogs/BlogCard';

const BlogsList = props => {

	const [ tmp, setTmp ] = useState( 0 )
	
	//const [ blogs, setBlogs ] = useState( Data().blogs || [] )

	/*
	useEffect(() => {
		Data().fetch( ( data ) => {
			//Store.set( 'blogs', data.blogs )
			//setBlogs( data.blogs )
			setTmp( tmp + 1 )
		} )
	}, [ ] )
	*/
	
	const limit = props.limit || 12

	/*
	const listClick = e => {
		Store.set( 'allActivities.scrollTop', document.body.scrollTop || document.documentElement.scrollTop || 0 )
	}
	*/

	//const blogs = Data().blogs
	const blogs = Store.get( 'blogs' ) || []

	if ( blogs.length === 0 ) return null

	return ( 
    	<div className="products" >
	    	<div style={{ verticalAlign:'top', textAlign:'left', marginLeft:'10px', marginRight:'30px', boxSizing:'border-box', }} >
	    		{ blogs.map( ( row, i ) => {
	    			if ( i >= Number( limit ) ) return <Fragment key={ row.slug  + '-' + i } />
    				return (
						<Fragment key={ row.slug  + '-' + i } >
				    		<Link 
	 				    		draggable="false" 
					    		to={ '/blogs/' + row.slug + '/' }
					    		className="blogs-list-block"
				    		>
				    			<BlogCard row={ row } />				    		
					    	</Link>
						</Fragment>
			    	)
		    	} ) }

		    </div>
		</div>
	)

}

export default BlogsList
