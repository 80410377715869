import parse from 'html-react-parser';

const ActivityPageItem = props => { 
	if ( !props.content && !props.lines ) return null
	if ( props.lines && props.lines.length === 0 ) return null
	if ( props.content && typeof props.content === 'undefined' ) return null

	//console.log( props.content, typeof props.content )

	return (
	  <>

    	<h3>{ props.title }</h3>

    	{ props.content && <div style={{ fontSize:'15px' }} >{ parse( ( props.content || ' ' ).toString() ) }</div> }

    	{ props.lines && 
		  <> 
		  	{ props.lines.map( ( a, i ) => {
		  		if ( !a || typeof a === 'undefied' ) return null
		  		return (
		    		<div key={ i } style={{ fontSize:'15px' }} >{ parse( ( a || '').toString() ) }</div>
	  			)
		  	} ) }
		  </>
    	}

	  </>
	) 
}

export default ActivityPageItem
