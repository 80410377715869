import React,{ Fragment } from 'react';
import Velocity from 'velocity-animate';

class GalleryView extends React.Component {

	constructor( props ) {
		super( props );
		this.state = { 
			tmp:null,
			duration:333.34,
		}
    	this.previousGalleryImage = this.previousGalleryImage.bind( this )
    	this.nextGalleryImage = this.nextGalleryImage.bind( this )
    	this.onTouchStart = this.onTouchStart.bind( this )
    	this.onTouchMove = this.onTouchMove.bind( this )
    	this.onTouchEnd = this.onTouchEnd.bind( this )
	}

	indexAdd( index, delta ) {
		let newIndex = index + delta
		if ( newIndex < 0 ) newIndex = this.props.images.length - 1
		if ( newIndex >= this.props.images.length ) newIndex = 0
		return newIndex 
	}

	componentDidMount() {
		this.imageIndex = this.props.imageIndex
		this.imageNodes = [ this.refs.previous, this.refs.current, this.refs.next ]
		/*
		new Image().src = this.props.images[ this.props.imageIndex ] // cache selected image first
		this.props.images.map( p => {
			new Image().src = p.derived[ 1 ].url
			return null
		} )
		*/
		Velocity( this.imageNodes[ 0 ], { top:'50%', translateY:'-50%', left:'-100%', opacity:0 }, { duration:0 } )
		Velocity( this.imageNodes[ 1 ], { top:'50%', translateY:'-50%', left:0, opacity:1 }, { duration:0 } )
		Velocity( this.imageNodes[ 2 ], { top:'50%', translateY:'-50%', left:'100%', opacity:0 }, { duration:0 } )

		this.touchstartX = 0
		this.touchstartY = 0
		this.touchendX = 0
		this.touchendY = 0
	    this.hasTouch = 'ontouchstart' in document.documentElement
	    this.hasMouse = 'onmousedown' in document.documentElement

	}

	onTouchStart( e ) {
		e.stopPropagation()
		e.preventDefault()
        var container = this.refs.container
        if ( container == null ) {
        	// do nothing
        } else if ( this.hasTouch && typeof e != 'undefined' && typeof e.changedTouches != 'undefined' ) {
	        container.addEventListener( 'touchmove', this.onTouchMove, false ); 
	        container.addEventListener( 'touchend', this.onTouchEnd, false );
		    this.touchstartX = e.changedTouches[ 0 ].clientX;
		    this.touchstartY = e.changedTouches[ 0 ].clientY;		
	    } else if ( this.hasMouse ) {
	        container.addEventListener( 'mousemove', this.onTouchMove, false );
	        container.addEventListener( 'mouseup', this.onTouchEnd, false );
		    this.touchstartX = e.clientX;
		    this.touchstartY = e.clientY;		
	    }
	}

	onTouchMove( e ) {
		e.stopPropagation()
		e.preventDefault()
		let rx
		let ry
		if ( this.touchstartX == null || this.touchstartY == null ) return;
        if ( this.hasTouch && typeof e != 'undefined' && typeof e.changedTouches != 'undefined' ) {
        	rx = e.changedTouches[ 0 ].clientX - this.touchstartX;
        	ry = e.changedTouches[ 0 ].clientY - this.touchstartY;
	    } else if ( this.hasMouse ) {
        	rx = e.clientX - this.touchstartX;
        	ry = e.clientY - this.touchstartY;
	    } else {
	    	return;
	    }
	    let xDelta = Math.abs( rx )
	    let yDelta = Math.abs( ry )
	    if ( xDelta < 32 && yDelta < 64 ) {
	    	// do nothing
	    } else if ( xDelta >= yDelta ) {
	        if ( rx > 0 ) {
		        e.preventDefault();
		        this.onTouchEnd( e );
	            this.previousGalleryImage( e )
	        } else {
		        e.preventDefault();
		        this.onTouchEnd( e );
				this.nextGalleryImage( e )
	        }
	    } else {
			e.stopPropagation()
	        e.preventDefault();
	    }
	}

	onTouchEnd( e ) {
		e.stopPropagation()
		e.preventDefault()
        var container = this.refs.container
        if ( container == null ) {
        	// do nothing
        } else if ( this.hasTouch ) {
	        container.removeEventListener('touchmove', this.onTouchMove, false );
	        container.removeEventListener('touchend', this.onTouchEnd, false );
	    } else if ( this.hasMouse ) {
	        container.removeEventListener('mousemove', this.onTouchMove, false );
	        container.removeEventListener('mouseup', this.onTouchEnd, false );
	    }
	}

 	previousGalleryImage( e ) {
		e.stopPropagation()
		this.imageNodes.unshift( this.imageNodes.pop() ) // last to first
		this.imageIndex = this.indexAdd( this.imageIndex, -1 )
		this.imageNodes[ 0 ].src = this.props.images[ this.indexAdd( this.imageIndex, -1 ) ]
		Velocity( this.imageNodes, "finish" )
		Velocity( this.imageNodes[ 0 ], { top:[ '50%', '50%' ], translateY:[ '-50%', '-50%' ], left:[ '-100%', '-200%' ], opacity:[ 0, 0 ] }, { easing:'easeOutQuad', duration:this.state.duration } )
		Velocity( this.imageNodes[ 1 ], { top:[ '50%', '50%' ], translateY:[ '-50%', '-50%' ], left:[ 0, '-100%' ], opacity:[ 1, 0 ] }, { easing:'easeOutQuad', duration:this.state.duration } )
		Velocity( this.imageNodes[ 2 ], { top:[ '50%', '50%' ], translateY:[ '-50%', '-50%' ], left:[ '100%', 0 ], opacity:[ 0, 1 ] }, { easing:'easeOutQuad', duration:this.state.duration, complete:() => {
		} } )
	}

	nextGalleryImage( e ) {
		e.stopPropagation()
		this.imageNodes.push( this.imageNodes.shift() ) // first to last
		this.imageIndex = this.indexAdd( this.imageIndex, 1 )
		this.imageNodes[ 2 ].src = this.props.images[ this.indexAdd( this.imageIndex, 1 ) ]
		Velocity( this.imageNodes, "finish" )
		Velocity( this.imageNodes[ 0 ], { top:[ '50%', '50%' ], translateY:[ '-50%', '-50%' ], left:[ '-100%', 0 ], opacity:[ 0, 1 ] }, { easing:'easeOutQuad', duration:this.state.duration } )
		Velocity( this.imageNodes[ 1 ], { top:[ '50%', '50%' ], translateY:[ '-50%', '-50%' ], left:[ 0, '100%' ], opacity:[ 1, 0 ] }, { easing:'easeOutQuad', duration:this.state.duration } )
		Velocity( this.imageNodes[ 2 ], { top:[ '50%', '50%' ], translateY:[ '-50%', '-50%' ], left:[ '100%', '200%' ], opacity:[ 0, 0 ] }, { easing:'easeOutQuad', duration:this.state.duration } )
	}

	render() {
		return ( 
			<Fragment>
			<div className="gallery-background" >
				<div ref="container" className="gallery-wrapper"  >
					<img ref="previous" draggable="false" alt=""  
						className="gallery-image-left"
						src={ this.props.images[ this.indexAdd( this.props.imageIndex, -1 ) ] } 
						onError={ e => { e.target.src = process.env.PUBLIC_URL + '/img/blue.png' } } 
					/>
					<img ref="current" draggable="false" alt=""  
						className="gallery-image"
						src={ this.props.images[ this.props.imageIndex ] } 
						onError={ e => { e.target.src = process.env.PUBLIC_URL + '/img/blue.png' } } 
					/>
					<img ref="next" draggable="false" alt=""  
						className="gallery-image-right"
					 	src={ this.props.images[ this.indexAdd( this.props.imageIndex, 1 ) ] } 
						onError={ e => { e.target.src = process.env.PUBLIC_URL + '/img/blue.png' } } 
				 	/>
					<div 
						style={{ position:'fixed', left:0, top:0, width:'100%', height:'100%' }} 
						//onMouseDown={ this.onTouchStart } 
						onTouchStart={ this.onTouchStart } 
					/>
					<div className="gallery-arrow-left" onClick={ this.previousGalleryImage } >
						<i style={{ fontSize:'32px' }} className="material-icons">arrow_back</i>
					</div>
					<div className="gallery-arrow-right" onClick={ this.nextGalleryImage } >
						<i style={{ fontSize:'32px' }} className="material-icons">arrow_forward</i>
					</div>
					<div className="gallery-close"  onClick={ this.props.hideGallery }  >
						<i style={{ fontSize:'32px' }} className="material-icons">close</i>
					</div>
				</div>
			</div>
			</Fragment>
		)
	}
}

export default GalleryView
