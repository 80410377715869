
const ActivityPageInfo = props => {
	return (
    	<div 
    		className="flex" 
    		style={{ marginBottom:'10px'  }} 
		>

			{ props.city && 
		    	<div 
		    		style={{ 
		    			fontSize:'14px', 
		    			marginRight:'10px' 
		    		}} 
	    		>
		    		<b>{ props.city }</b>
				</div>
			}
			{ props.difficultyLevel && 
    			<div style={{ height:'30px', margin:'0 10px 0 0' }} >
	    			<div 
	    				style={{ 
	    					display:'inline-block', 
	    					background:'#0277bd', 
	    					color:'#fff', 
	    					fontSize:'12px', 
	    					borderRadius:'12px', 
	    					fontWeight:900, 
		    					padding:'0 10px' 
	    				}} 
    				>
    					{ props.difficultyLevel.replace( '_', ' ' ) }
					</div>
				</div>
			}
			{ props.durationText && 
		    	<div style={{ fontSize:'14px' }} >
	    			{ props.durationText.toLowerCase() }
				</div>
			}
		</div>	
	)
}

export default ActivityPageInfo
