import React, { Fragment } from 'react';

const Pills = props => (
	<div className="products" style={{ padding:'25px 10px 20px 20px', textAlign:'left' }} >
	{ props.productLists.map( ( a, i ) => {
		if ( i >= Number( 8 ) ) return <Fragment key={ a.productListIndex + '-' + i } />
		return <div 
			key={ a.productListIndex + '-' + i }
			className= { 'all-activities-pills ' + ( i === props.productListIndex ? 'all-activities-pills-selected' : '' ) }
			onClick={ () => { props.selectTab( i ) } }
		>{ a.title }</div>
	} ) }
	</div>
)

export default Pills
