import React,{ Fragment } from 'react';
import parse from 'html-react-parser';
import Data from '../Data/Data';
import BreadCrumbs from '../App/BreadCrumbs';
import ActivitiesList from '../Activities/ActivitiesList';

class CategoryPage extends React.Component {

	constructor( props ) {
		super( props );
		this.state = { 
			tmp:null,
			productListIndex:0,
		};
	}

	componentDidMount() {
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
		Data.productLists.forEach( ( a, i ) => {
			if ( a.productListId === this.props.match.params.productListId ) {
				this.setState( { productListIndex:i } )
			}
		} )
	}

	render() {

		let backTo = '/activities/'
		if ( this.props.location.state && this.props.location.state.backFromPageTo && this.props.location.state.backFromPageTo === 'back' ) {
			backTo = 'back'
		}

		return ( 
			<Fragment>

	    		<BreadCrumbs 
	    			to={ backTo }
	    			history={ this.props.history }
	    			img={ process.env.PUBLIC_URL + '/img/fi/' + Data.productLists[ this.state.productListIndex ].img } 
	    			titles={ [ 'Top Categories', Data.productLists[ this.state.productListIndex ].title ] } 
    			/>

		        <div className="products" >
	    			<div style={{ padding:'20px 30px 10px 30px' }} >
				    	<h1 style={{ lineHeight:'50px', margin:'20px 0 0 0', textAlign:'left' }} >{ Data.productLists[ this.state.productListIndex ].title }</h1>
			    	</div>
		    	</div>

		        <div className="products" >
	    			<div style={{ textAlign:'left', padding:'0 30px 10px 30px' }} >
		 		    	{ parse( Data.productLists[ this.state.productListIndex ].copy || '' ) }
			    	</div>
		    	</div>

		        <div className="products" >
	    			<div style={{ padding:'0 30px 10px 30px' }} >
				    	<h1 style={{ lineHeight:'40px', margin:'10px 0 0 0', textAlign:'left' }} >Book { Data.productLists[ this.state.productListIndex ].title } Activities</h1>
			    	</div>
		    	</div>

				<ActivitiesList
					limit="24"
			        backFromPageTo="back" 
					productListId={ this.props.match.params.productListId }
				/>

			</Fragment>
		)
	}
}

export default CategoryPage

