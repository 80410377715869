const Search = props => {
	return (
		<div style={{ position:'relative', padding:'0 0 10px 10px', width:'100%', maxWidth:'559px' }} >
			<input 
				value={ props.search } 
				style={{ paddingLeft:'32px', paddingRight:'32px', width:'100%', minWidth:'100%', boxSizing:'border-box' }}
				placeholder={ 'Search ' }
				onChange={ e => { props.setSearch( e.target.value ) } } 
			/>
			<div
				className="material-icons" 
				style={{ position:'absolute', left:'18px', top:'6px', color:'#999'  }}
			>
				search
			</div>
			<div
				onClick={ () => { props.setSearch( '' ) } }
				className="material-icons" 
				style={{ position:'absolute', cursor:'pointer', right:'6px', top:'6px', color:'#999'  }}
			>
				close
			</div>
		</div>
	)
}

export default Search
