export const imageNames = { 

  fi:[
    './fi/aurora.jpg',
    './fi/DSC8304_8359_4232.jpg',
    './fi/Finland_skating_lake_Saimaa.jpg',
    './fi/Myhelsinkiflow.jpg',
    './fi/photo-03-10-2018.jpg',
    './fi/TU-181227-ala-oodi-018.jpg',
    './fi/Finnish_National_Opera_Katsomo 11_Heikki_Tuuli.jpg',
    './fi/SibeliusFinlandExperience-Sibelius-monument.jpg',
    './fi/PUNKAHARJU_SAIMAA.jpg',
    './fi/AW3I7129-HDR.jpg',
  ],

  ae:[
    './ae/Adu-Dhabi-Persian-Gulf-Beach.jpg',
    './ae/Dubai-Bazar.jpg',
    './ae/Dubai-Miracle-Garden.jpg',
    './ae/Dubai-Modern-People.jpg',
    './ae/Dubai-Skydiving-.jpg',
    './ae/Dubai-Skyline.jpg',
    './ae/Dune-Safari-Dubai.jpg',
    './ae/Dune-Trekking-Dubai.jpg',
    './ae/Jumeirah-Palm-Beach-View.jpg',
    './ae/Luxury-Glambing-Dubai.jpg',
    './ae/Surfing-Dunes-Dubai.jpg',
    './ae/Waterways-of-Dubai.jpg',
  ],

  is:[
    './is/black-sand-sandur.jpg',
    './is/hotel-ranga.jpg',
    './is/longufjorur-mark.jpg',
    './is/longufjorur-mark-vest.jpg',
    './is/nordurljos-vid-jokulsarlon-fon.jpg',
    './is/strokkur.jpg',
    './is/videy-fridarljos-lennon.jpg',
  ],

  qa:[
    './qa/stopover.doha-architecture-026.jpg',
    './qa/mangroves.jpg',
    './qa/Mosques_CultureHeritage10.jpg',
    './qa/BBP_6443.jpg',
    './qa/104A0492-2.jpg',
    './qa/beach.jpg',
    './qa/MIA_Museum 007.jpg',
  ]

}
